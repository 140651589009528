import PromoCode from "../apis/promocode";

const state = {
  listPromocodes: [],
};
const actions = {
  updatePromoCode({ commit }, data) {
    return PromoCode.show(data).then((response) => {
      if (response.data.code == 200) {
        commit("promocodeMutations", response.data.Result);
      }
      return response.data;
    });
  },
  promocodeRedeem({ commit }, data) {
    return PromoCode.couponRedeem(data).then((response) => {
      if (response.data.code == 200) {
        return response.data;
      }
      return response.data;
    });
  },
  autoApplyCouponRedeem({ commit }, data) {
    return PromoCode.autoApplyCoupon(data).then((response) => {
      if (response.data.code == 200) {
        return response.data;
      }
      return response.data;
    });
  },
};
const mutations = {
  promocodeMutations(state, data) {
    state.listPromocodes = data;
  },
};
const getters = {
  listPromocodes: (state) => state.listPromocodes,
};

export const promocode = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
